import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { Typography } from '@material-ui/core';
import WorkIcon from '@material-ui/icons/Work';
import InfoIcon from '@material-ui/icons/Info';
import  './timeline.css';


const useStyles = makeStyles({
    timelineStyles:{
        padding:'0 !important',
    },
    timeLineHeader:{
        paddingTop: 7
    },
    timeLineItem:{
        minHeight:'80px !important'
    },
    dotHeader:{
        color: '#000000',
        backgroundColor:'#00CED1',
        fontSize: 'small',
        padding: 12
    },
    dotColor:{
        backgroundColor:'#00CED1'
    },
    timeLineDot:{
        color: '#1E90FF',
        backgroundColor:'#00CED1',
        padding: 2
    },
    separatorPadding:{
        paddingLeft:'22px !important'
        
        
    }
})



const TimelineCustom = ({title, icon, children}) => {

    const classes = useStyles()

    return ( 
        <div>
            <Timeline className={classes.timelineStyles,"timeline-style"} align="left">
            <TimelineItem className={classes.timeLineItem}>
                <TimelineSeparator>
                <TimelineDot className={classes.dotHeader}>{icon}</TimelineDot>
                <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Typography className={classes.timeLineHeader} variant="h6">{title}</Typography>
                </TimelineContent>
            </TimelineItem>
            {children}



            </Timeline>
        </div>
     );
}


const CustomTimeLineSeparator = () => {
    const classes = useStyles()
    return ( 
       
          <TimelineSeparator className={classes.separatorPadding}>
            <TimelineDot className={classes.timeLineDot} />
            <TimelineConnector/>
            </TimelineSeparator>
     );
}

// const CustomTimeLineSeparator = () => (
//          <TimelineSeparator className={'separator-padding'}>
//             <TimelineDot className={'timeline-dot'} />
//            <TimelineConnector/>
//         </TimelineSeparator>
// );
 
export  {CustomTimeLineSeparator};
 
export default TimelineCustom;