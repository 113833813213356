import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';

import NotFound from '../../assets/img/404.gif';
import '../404/404.css';


const useStyles = makeStyles({
    title:{
        fontSize: '3rem',
        padding:40,
        color:'#FF0000',
        fontWeight:'bold'
    }
})

const PageNotFound = () => {

    const history = useHistory();
    const classes = useStyles();

    // Redirects to the home page after 5 seconds
    useEffect(() =>{
        setTimeout(()=>{
            history.push('/');
        },5000)
    },[])

    return ( 
        <>
        <header>
            <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
             This is an error alert — <strong>something went wrong!</strong>
             <hr/>
             <p>You will be redirected to Home Page in 5 sec...</p>
            </Alert>
         </header>
         <Grid className='alert-section' container>
            <Grid container item xs={12} direction="row"
            justify="center"
            alignItems="center">
                 <Typography className={classes.title} variant='h2'>Page Not Found!</Typography>
            </Grid>
            <Grid container item xs={12} direction="row"
            justify="center"
            alignItems="center">
                 <hr/>
                 <img className='alertIcon' src={NotFound}></img>
            </Grid>
        
         </Grid> 
        </>

         
     );
}
 
export default PageNotFound;