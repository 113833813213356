import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {Button, Form, FormControl, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {HomeRounded, SchoolRounded, WorkRounded, Facebook, LinkedIn, GitHub, Telegram} from '@material-ui/icons';
import CodeIcon from '@material-ui/icons/Code';



import '../Header/Header.css';
import ResumeData from '../../utils/resumeData';
import CustomButton from '../Button/Button';
import DropDownButton from '../Button/DropDownButton';
import { motion } from "framer-motion";

const Header = (props) => {

    const path = props?.location?.pathname

    return ( 
        <Navbar className='header' expand="lg" sticky='top'>
            {/* Home Link */}
            <Nav.Link className={'p-0 m-0'} as={NavLink} to='/'>
               <Navbar.Brand className='header-home'>
                    <CodeIcon/>
                </Navbar.Brand> 
            </Nav.Link>

            <Navbar.Toggle/>

            <Navbar.Collapse>
                <Nav className='header-left'>
                <motion.div whileHover={{ scale:0.9, transition: { duration: 0.4 } , textShadow: "0px 0px 8px rgb(255,255,255)", boxShadow:"0px 0px 8px rgb(255,255,255)"}}>
                    <Nav.Link className={path == '/' ? 'header-active' : 'header-link'} as={NavLink} to='/'>
                        About
                    </Nav.Link>
                </motion.div>

                <motion.div whileHover={{scale: 0.9, transition: { duration: 0.4 }, textShadow: "0px 0px 8px rgb(255,255,255)", boxShadow:"0px 0px 8px rgb(255,255,255)"}}>
                    <Nav.Link className={path == '/education' ? 'header-active' : 'header-link'} as={NavLink} to='/education'>
                        Education
                    </Nav.Link>
                </motion.div>

                    <motion.div whileHover={{ scale: 0.9,
                     transition: { duration: 0.4 }, textShadow: "0px 0px 8px rgb(255,255,255)", boxShadow:"0px 0px 8px rgb(255,255,255)"}}>
                    <Nav.Link className={path == '/skills' ? 'header-active' : 'header-link'} as={NavLink} to='/skills'>
                        Skills
                    </Nav.Link>  
                    </motion.div>
                    <motion.div whileHover={{ scale: 0.9,
                     transition: { duration: 0.4 }, textShadow: "0px 0px 8px rgb(255,255,255)", boxShadow:"0px 0px 8px rgb(255,255,255)"}}>
                    <Nav.Link className={path == '/projects' ? 'header-active' : 'header-link'} as={NavLink} to='/projects'>
                        Projects
                    </Nav.Link>  
                    </motion.div>

                </Nav>
                <div className='header-right'>
                    {Object.keys(ResumeData.socialMedia).map(key =>(
                        <motion.a 
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }} href={ResumeData.socialMedia[key].link} key={key.toString()} target='_blank'>{ResumeData.socialMedia[key].icon}</motion.a>
                    ))}
                    <DropDownButton text={'Contact Me'}/>
                </div>
            </Navbar.Collapse>
        </Navbar>
     );
}
 
export default withRouter(Header);