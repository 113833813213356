import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Card from "react-bootstrap/Card";

import '../resume/Resume.css';
import ResumeData from '../../utils/resumeData';


import banner from '../../assets/img/banner.png'
import { CardDeck } from 'react-bootstrap';
import Skills from '../skills/Skills';

import { motion } from "framer-motion";


const Resume = () => {
    return ( 

        <>
         {/* About Section */}
            <Grid className='section pb-spacing pt-45' >
                <Grid className='section-title mb-spacing' >
                    
                 <Typography className='title-text' variant='h6'><span className='line'></span>About Me</Typography>
                 
                </Grid>
                <motion.div initial={{y: -350}} animate={{y: -10}} transition={{delay: 0.1, type: 'spring', stiffness: 90 }}>
                    <Grid container item xs={12} direction="row"
                    justify="center"
                    alignItems="center">
                    <p  className='about-me-text'>{ResumeData.about}</p>
                    <img className='banner' src={banner}></img>
                    </Grid>
                </motion.div>

      
            </Grid>

            <motion.div initial={{y: -350}} animate={{y: -10}} transition={{delay: 0.1, type: 'spring', stiffness: 90 }}>
                            {/*  Section*/}
                <Grid container className='section p-50 pb-spacing'>
                <CardDeck >
                    {ResumeData.cards.map((card, index) =>(
                        
                        <Card key={index} border="info">
                        <motion.div    
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}>
                        <Card.Body>
                        <Card.Title className='card-title-styles'><img className='card-icons' src = {card.icon}></img></Card.Title>
                        <hr/>
                        <Card.Title className='card-header-styles'><Typography variant='h6'>{card.title}</Typography></Card.Title>
                        
                        <Card.Text className='card-description-styles'>
                            {card.description}
                        </Card.Text>
                        </Card.Body>
                        </motion.div>

                        </Card>
                    ))}
                    </CardDeck>
                </Grid>
            </motion.div>



            {/* Skills Section*/}
            <Grid className='section' container>

            </Grid> 

            {/* Contact Section*/}
            <Grid className='section' container>

            </Grid>           
        </>

     );
}
 
export default Resume;