import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import Badge from 'react-bootstrap/Badge';
import { motion } from "framer-motion";

import ResumeData from '../../utils/resumeData';

import '../skills/Skills.css';
import TimelineDot from '@material-ui/lab/TimelineDot';

import IconCSharp from '../../assets/img/csharp.png';
import IconHtml from '../../assets/img/html.png';
import IconCSS from '../../assets/img/css.png';
import IconBootstrap from '../../assets/img/bootstrap.png';
import IconJavaScript from '../../assets/img/javascript.png';
import IconSQL from '../../assets/img/sql2.png';
import IconPHP from '../../assets/img/php.png';
import IconFirebase from '../../assets/img/firebase.png';
import IconMYSQL from '../../assets/img/mysql.png';
import IconMUI from '../../assets/img/mui.png';
import IconReact from '../../assets/img/react.png';

//? Motion animated image variants
const iconsVariants ={
    hidden:{ rotate: -180},
    visible: {
        rotate: 0,
        transition:{duration: 2, yoyo:Infinity,  ease: "easeInOut" }
    }
}


const Skills = () => {
    return ( 
    <>
        {/*Skills  Section*/}

        <Grid container className='section pt-45'>

        <Grid className='section-title mb-spacing' >
                        
            <Typography className='title-text' variant='h6'><span className='line'></span>Skills</Typography>
                    
            </Grid>

        </Grid>

     <motion.div initial={{y: -350}} animate={{y: -2}} transition={{delay: 0.1, type: 'spring', stiffness: 90 }}>

     
        <Grid className='section graybg pb-spacing p-50' container >
            <Grid  item xs={12}>
                <Grid container justify='space-between' spacing={3}>
                {ResumeData.skills.map((skill, index)=> (
                <Grid key={index} item xs={12} sm={6} md={3}>
                    <Grid >
                      <motion.div     
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}>
                        <Paper className='skill' elevation={2}>
            
                            <Typography variant='h6' className='skill-title'>
                                <Badge variant="info" >{skill.title}</Badge>
                            </Typography>
                           

                            {skill.description.map((i, index) => (
                                <Typography  key={index}  variant='body2' className='skill-description'>
                                    <TimelineDot variant={'default'} className='timeline-dot'/>
                                   {i}
                                </Typography>
                            ))}
                        </Paper>
                      </motion.div>

                       
                    </Grid>
                   
                </Grid>
                
            ))}
                </Grid>
            </Grid>

        </Grid>
        <hr/>
        <Grid container className='section p-50 pb-spacing' >
            <Grid item xs={12} >
                <div className='skills-section'>
                    <img src={IconBootstrap} alt="bootstrap"></img>
                    <img src={IconCSS} alt="css"></img>
                    <img src={IconCSharp} alt="csharp"></img>
                    <img src={IconJavaScript} alt="javascript"></img>
                    <img src={IconMYSQL} alt="mysql"></img>
                    <img src={IconReact} alt="react"></img>
                </div>
            </Grid>
            <Grid item xs={12} >
                <div className='skills-section'>
                    <img src={IconSQL} alt="sql"></img>
                    <img src={IconHtml} alt="html"></img>
                    <img src={IconPHP} alt="php"></img>
                    <img src={IconFirebase} alt="firebase"></img>
                    <img src={IconMUI} alt="mui"></img>
                </div>
            </Grid>

        </Grid>
        </motion.div>
    </>
     );
}
 
export default Skills;

//? Animated image
{/* <motion.img src={IconCSharp} alt="csharp"
variants={iconsVariants}
initial="hidden"
animate="visible"
></motion.img> */}