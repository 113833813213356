import { Grid, Typography } from '@material-ui/core';
import { motion } from "framer-motion";
import { Button } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import Badge from 'react-bootstrap/Badge';




import ResumeData from '../../utils/resumeData';
import '../projects/Projects.css';



const Project = () => {
    return ( 
      
    <>
      {/* Projects Section */}
  
     <Grid className='section pb-spacing pt-45' >
                <Grid className='section-title mb-spacing' >
                    
                 <Typography className='title-text' variant='h6'><span className='line'></span>Projects</Typography>
                 
                </Grid>

                <motion.div initial={{y: -350}} animate={{y: -10}} transition={{delay: 0.1, type: 'spring', stiffness: 90 }}>
                    
                <Grid container className='section p-50 pb-spacing project-section'>
  
                    {ResumeData.projectCards.map((projectcard, index) =>(
                        
                        <motion.div  key={index} whileHover={{ scale: 1.1 }}  whileTap={{ scale: 0.9 }}>
                        <Card className='prj-card' key={index} >
                        <Card.Img variant="top" src={projectcard.icon} />
                        <Card.Body>
                            <Card.Title>  <Badge pill variant="info">{projectcard.title}</Badge></Card.Title>
                            <Card.Text className={'description-p'}>
                                {projectcard.description}
                            </Card.Text>
                            <a href={projectcard.link} target="_blank">
                                     <Button variant="info">View Project</Button>
                            </a>
                       
                        </Card.Body>
                        </Card>
                        </motion.div>
                    ))}
                   
                </Grid>
                </motion.div>

      
            </Grid>

     
    </> 
    );
}
 
export default Project;