import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import TimeLineCuston,{CustomTimeLineSeparator} from '../Timeline/Timeline';
import GetAppIcon from '@material-ui/icons/GetApp';
import '../Profile/Profile.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

import ResumeDocx from '../../pages/resume/Resume.docx';

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Badge from 'react-bootstrap/Badge';


import ProfilePicture from '../../assets/img/mainpicture.jpg';
import ResumeData from '../../utils/resumeData';
import CustomButton from '../Button/Button';

import { motion } from "framer-motion";


const useStyles = makeStyles({
    profile:{
        backgroundColor: '#F8F8FF',
        borderRadius: '5px',
        width: '100%',
        display: 'inline-block'
    },
    profName:{
        lineHeight: '18px',
        padding: '20px'
    },
    name:{
        textTransform: 'uppercase',
        fontWeight:'bold',
        fontSize: 18
    },
    title:{
        fontSize: 18,
        color: '#1E90FF'
    },
    profPicture:{
        marginTop: -10,
        WebkitClipPath: 'polygon(0 9%, 100% 0, 100% 94%, 0% 100% )',
        clipPath: 'polygon(0 9%, 100% 0, 100% 94%, 0% 100%)'
    },
    imgStyles:{
        width:'100%'
    },
    timeLineDot:{
        color: '#1E90FF',
        backgroundColor:'#00CED1',
        padding: 2
    },
    separatorPadding:{
        paddingLeft:'22px !important'
    },
    profileInfo:{
        top:0,
        left:0,
        margin: '-40px 10px 30px'
    },
    shadow:{
        boxShadow:'0px 2px 92px 0px rgba(0, 0, 0, 0.13)',
        WebkitBoxShadow:'0px 2px 92px 0px rgba(0, 0, 0, 0.13)',
        MozBoxShadow:'0px 2px 92px 0px rgba(0, 0, 0, 0.13)'
    },
    btnContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        padding:'0px 0px 20px 0px'
    },
    content:{
        marginTop: '2px',
        marginBottom: '10px'
    },
    emailStyles:{
        fontFamily:'Montserrat, sans-serif'
    }
})



const Items = ({title, text, link}) =>{
    const classes = useStyles();
   return(
    <TimelineItem >
        <CustomTimeLineSeparator />
        <TimelineContent  className={classes.content}>
            {link ? (<Typography className="timeline-text">
                <span>{title}:</span>{" "} <a href={link} target='_blank'>{text}</a>
            </Typography>) : (
                <Typography className="timeline-text"> <span>{title}:</span> {text} </Typography>
            )}
        </TimelineContent>
    </TimelineItem>
    )
}

const Profile = () => {

    const classes = useStyles();
    return ( 
        <div className={classes.profile, classes.shadow}>
        <div className={classes.profName}>
            <Typography className={classes.name}>
                {ResumeData.name}
            </Typography>
            <Typography className={classes.title}>
                <Badge pill variant="info">{ResumeData.title} <FontAwesomeIcon icon={faCode}></FontAwesomeIcon></Badge>
            </Typography>
        </div>
        <div className={classes.profPicture}>
            <img className={classes.imgStyles} src={ProfilePicture} alt="profile-pic"></img>
        </div>
        <div className={classes.profileInfo}>
           <TimeLineCuston icon={<InfoIcon/>}>
               {/* <Items title='Name' text={ResumeData.name}/> */}
               <Items title='Email' className={classes.emailStyles} text={ResumeData.email}/>

                {/* Let's map links in our ResumeData */}
               {Object.keys(ResumeData.socialMedia).map(key =>(
                    <Items title={key} key={key.toString()} text={ResumeData.socialMedia[key].text} link={ResumeData.socialMedia[key].link}/>
               ))}
               
           </TimeLineCuston>
            
            <div className='download-button-container'>
                <a  href={ResumeDocx} download="Resume.docx">
                    <CustomButton text={'Download CV'} icon={<GetAppIcon/>}/>
                </a>
                 
            </div>
      
        </div>
    </div>
     );
}
 
export default Profile;