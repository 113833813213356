import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { motion } from "framer-motion";


const useStyles = makeStyles({
    customBtn:{
        backgroundColor: '#00CED1 !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        border: '0 !important',
        borderRadius: 50,
        padding: '1px 12px !important',
        lineHeight: '35px !important',
        minWidth: '100px !important',
        outline: 'none !important'

    },
    btnIcon:{
        color: '#000',
        background: '#fff',
        borderRadius: '50px !important',
        height: '27px !important',
        width: '27px !important',
        lineHeight: '29px !important',
        textAlign: 'center',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    btnText:{
        fontSize: '14px !important',
        textTransform: 'none',
        textAlign: 'center',
        width: '100%',
        marginRight: 5,
        marginLeft: 5
    }
})

const buttonVariants = {
    hover:{
        scale:1.1,
        textShadow: "0px 0px 8px rgb(255,255,255)", 
        boxShadow:"0px 0px 8px rgb(255,255,255)",
        transition:{
            duration: 0.3,
            yoyo: Infinity
        }
    }
}

const CustomButton = ({text, icon}) => {
    const classes = useStyles();

    return ( 
        <motion.div 
        variants={buttonVariants}
        whileHover="hover"
        >
            <Button className={classes.customBtn} variant="contained" color="primary" endIcon={icon ? (<div className={classes.btnIcon}>{icon}</div>) : null }>
            <span className={classes.btnText}>{text}</span>
        </Button>
        </motion.div>

    );
}
 
export default CustomButton;