import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-198045147-1');
ReactGA.pageview(window.location.pathname + window.location.search);

// Changed React.StrictMode to App to avoid error in console
ReactDOM.render(
  <App>
    <App />
  </App>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


{/* <React.StrictMode>
<App />
</React.StrictMode> */}