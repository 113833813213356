import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Profile from './components/Profile/Profile';
import Availability from './components/Availability/Availability';
import Header from './components/Header/Header';
import Education from './pages/education/Education';
import Resume from './pages/resume/Resume';
import Skills from './pages/skills/Skills';
import Projects from './pages/projects/Projects';
import Footer from './components/Footer/Footer';
import PageNotFound from './pages/404/404';
import './App.css';

// Google Analytics imports
// import ReactGA from 'react-ga';

// ReactGA.initialize('UA-198045147-1');
// ReactGA.pageview(window.location.pathname + window.location.search);
// import { createBrowserHistory } from 'history';

// const trackingID = "UA-198045147-1";
// ReactGA.initialize(trackingID);
// const history = createBrowserHistory();

// // Initialize google analytics page view tracking
// history.listen(location => {
//   ReactGA.set({ page: location.pathname});// Update the user's current page
//   ReactGA.pageview(location.pathname);// Record a pageview for the given page
// })






function App() {
  return (
    <div className="App">
      <Container className={'top-spacing'}>
        <Grid container spacing={7}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Profile/>

            <Availability/>
          </Grid>
          <Grid item xs>
            <Router>
            <Header/>
            <div className='main-content shadow'>
              <Switch>
                <Route path="/education">
                  <Education/>
                </Route>
                <Route path="/skills">
                  <Skills/>
                </Route>
                <Route path="/projects">
                  <Projects/>
                </Route>
                <Route exact path="/">
                    <Resume/>
                </Route>
                <Route path="*">
                    <PageNotFound/>
                </Route>
              </Switch>
            </div>
            </Router>
            <Footer/>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}

export default App;
