import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SchoolIcon from '@material-ui/icons/School';

import '../education/Education.css';
import TimeLineCuston,{CustomTimeLineSeparator} from '../../components/Timeline/Timeline';
import ResumeData from '../../utils/resumeData';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineItem from '@material-ui/lab/TimelineItem';
import Badge from 'react-bootstrap/Badge';
import Particles from 'react-particles-js';


import NAIT from '../../assets/img/nait.png';
import GraduationHat from '../../assets/img/graduation-hat.png';
import IconCode from '../../assets/img/code.png';
import { motion } from "framer-motion";



const Education = () => {
    return ( 
        <div>
            {/*Education  Section*/}
            <Grid className='section pt-45' container>

                <Grid className='section-title mb-spacing' >
                        
                 <Typography className='title-text' variant='h6'><span className='line'></span>Education</Typography>
                        
                </Grid>
                
                <Grid item xs={12}>
                    <motion.div initial={{y: -350}} animate={{y: -10}} transition={{delay: 0.1, type: 'spring', stiffness: 90 }}>
                        <Grid container >
                        {/* Education */}
                        <Grid item sm={12} md={8}>
                            <TimeLineCuston  icon={<SchoolIcon/>}>
                                {ResumeData.education.map((education,index) => (
                                    // index is a unique key for the list items to avoid errors in console.
                                    <TimelineItem key={index}>
                                        <CustomTimeLineSeparator/>

                                        <TimelineContent >
                                           <Typography  className="timeline-description">{education.description}</Typography>
                
                                            <Typography  className="timeline-path">{education.path}</Typography>
                                            <Typography className="timeline-major">{education.major}</Typography>
                                            <Typography className="timeline-title"><Badge pill variant="info">{education.title}</Badge></Typography>
                                            <Typography variant='body1' className="timeline-date"><Badge variant="Secondary">{education.date}</Badge></Typography>
                                          
                                        </TimelineContent>
                                        

                                    </TimelineItem>
                                    
                                ))}
                            </TimeLineCuston>
                        </Grid>
                        {/* Education */}
                        <Grid item sm={12} md={4}>
                        <img src={NAIT} alt="nait"></img>
                        </Grid>

                    </Grid>
                    </motion.div>

                    {/* Particles.js */}
                    <Particles
                    params={{
                        "particles": {
                            "number": {
                                "value": 8,
                                "density": {
                                    "enable": true,
                                    "value_area": 900
                                }
                            },
                            "line_linked": {
                                "enable": false
                            },
                            "move": {
                                "speed": 1,
                                "out_mode": "out"
                            },
                            "shape": {
                                "type": [
                                    "image"
                                    // "circle"
                                ],
                                "image": [
                                    {
                                        "src": `${NAIT}`,
                                        "height": 20,
                                        "width": 23
                                    },
                                    {
                                        "src": `${NAIT}`,
                                        "height": 20,
                                        "width": 20
                                    },
                                    {
                                        "src": `${GraduationHat}`,
                                        "height": 20,
                                        "width": 20
                                    },
                                    {
                                        "src": `${IconCode}`,
                                        "height": 20,
                                        "width": 20
                                    }
                                ]
                            },
                            "color": {
                                "value": "#00CED1"
                            },
                            "size": {
                                "value": 30,
                                "random": false,
                                "anim": {
                                    "enable": true,
                                    "speed": 3,
                                    "size_min": 10,
                                    "sync": false
                                }
                            }
                        },
                        "retina_detect": false
                    }} />
                </Grid>
            </Grid>
        </div>
     );
}
 
export default Education;