import React from 'react';
import { motion } from "framer-motion";
import { makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import Card from "react-bootstrap/Card";
import { CardDeck } from 'react-bootstrap';
import CaseImg from '../../assets/img/case.png';

import Badge from 'react-bootstrap/Badge';
import '../Availability/Availability.css';

import ResumeData from '../../utils/resumeData';


const useStyles = makeStyles({
    profile:{
        backgroundColor: '#F8F8FF',
        borderRadius: '5px',
        width: '100%',
        display: 'inline-block'
    },
    shadow:{
        boxShadow:'0px 2px 80px 0px rgba(0, 0, 0, 0.10)',
        WebkitBoxShadow:'0px 2px 92px 0px rgba(0, 0, 0, 0.10)',
        MozBoxShadow:'0px 2px 92px 0px rgba(0, 0, 0, 0.10)'
    },
    title:{
        textTransform: 'uppercase',
        fontWeight:'bold',
        fontSize: 18,
        textAlign:'center',
        padding: '8px 0'
    }

})

const badgeVariants = {
    hover:{
        scale:1.1,
        textShadow: "0px 0px 8px rgb(255,255,255)", 
        boxShadow:"0px 0px 8px rgb(255,255,255)",
        transition:{
            duration: 0.3,
            yoyo: Infinity
        }
    }
}

const Availability = () => {
    const classes = useStyles();
    return ( 
        <>
        <div  className={ classes.shadow}>
            <CardDeck >
            {ResumeData.availability.map((i, index) =>(

                <Card key={index}  className={classes.profile, classes.shadow}>
                    <div>
                    <Card.Body>
                    <Card.Title className='card-title-styles avTitle'>{ResumeData.avalabilityTitle} <img className='case-img' src={CaseImg} alt="case-icon"/></Card.Title>
                    <hr/>
                    <motion.div
                    
                    animate={{scale: 1.1,
                        boxShadow:"0px 0px 8px rgb(255,255,255)",
                        textShadow: "0px 0px 8px rgb(250,250,250)"
                    }}
                    transition={{duration: 0.3,
                    yoyo: Infinity}}

                    >
                    <Card.Title className='card-header-styles availability'><Typography variant='h6'> <Badge pill variant="warning">{i.notAvailable}</Badge></Typography></Card.Title>
                    </motion.div>
                    
                    
                    <Card.Text className='card-description-styles'>
                    
                    </Card.Text>
                    </Card.Body>
                    </div>

                </Card>
            ))}
                    
                        
 
                   
            </CardDeck>
        </div>
        </>
     );
}
 
export default Availability;
