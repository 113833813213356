import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import IconRecycle from '../assets/img/recycle.png';
import IconKnowledge from '../assets/img/knowledge.png';
import IconHappyFace from '../assets/img/happy.png';
import FinancialImg from '../../src/assets/img/financial .png';
import MyStockImg from '../../src/assets/img/stock.png';
import MyWeatherAppImg  from '../../src/assets/img/weatherApp.png';





export default{
    name: 'Pavlo Shchegol',
    title: 'Full-Stack Developer',
    email:'pavsh@yahoo.com',
    phone:'780-257-2493',
    about:'I am a Junior Software Developer who graduated from NAIT with Diploma in Digital Media & IT Technology. Currently, I am taking the BAIST(Bachelor of Applied Information Systems Technology - Information Systems) program at NAIT. Always willing to learn new technologies and improve my skills. Also, I am a strong team player who inspires others to work. \n\nTo keep myself updated with the latest technologies, I spend considerable time reading technology blogs and online groups related to technology and coding.',
    avalabilityTitle:'Availability',

    socialMedia:{
        GitHub:{
            link:'https://github.com/pshchegol1',
            text:'GitHub',
            icon:<GitHubIcon/>
        },
        Linkedin:{
            link:'https://www.linkedin.com/in/pavlo-shchegol-38a06119a/',
            text:'LinkedIn',
            icon:<LinkedInIcon/>
        },
        Facebook:{
            link:'https://www.facebook.com/profile.php?id=100013244742377',
            text:'Facebook',
            icon:<FacebookIcon/>
        }
    },
    education:[
        {
            
            description: 'Northern Alberta Institute of Technology'
        },
        {
            path: 'Digital Media & IT',
            major: 'Computer Software Development',
            title:'Diploma',
            date: '2018 - 2021'
        },
        {
            
            description: 'Northern Alberta Institute of Technology'
        },
        {
            path: 'Bachelor of Applied Information Systems Technology',
            major: 'Information Systems',
            title:'Degree',
            date: '2021 - 2024'
        }
    ],
    skills:[
        {
            title: 'Front-End',
            description: 
            [
                "JavaScript",
                'Material UI',
                'Bootstrap',
                'ReactJS',
                'HTML5',
                'CSS'
            ]
        },
        {
            title:'Back-End',
            description: 
            [
                'Express.js',
                'C#',
                'PHP',
                'ASP.NET'
            ]
        },
        {
            title:'Database',
            description: 
            [
                'MSSQL Server',
                'MySQL',
                'Firebase'
            ]
        }
    ],
    cards:[
        {
            title:'Continuous Learning',
            description:'Constant expansion of skills through learning new technologies and development techniques.',
            icon: IconRecycle
        },
        {
            title:'Apply New Knowledge',
            description:'Apply new knowledge on projects that will help you determine whether you learned enough or not.',
            icon:IconKnowledge
        },
        {
            title:'Happy Client',
            description:'Understand the client’s needs and develop the best product that will meet their criteria.',
            icon: IconHappyFace
        }

    ],
    projectCards:[
        {
            title: 'Financial Tracker',
            description:'You can track your expenses by entering your description, choosing a type such as debit or credit, and the amount.',
            link: 'https://financial-tracker-ps.netlify.app',
            icon: FinancialImg
        },
        {
            title: 'My Stock Ticker',
            description:'You can enter any stock ticker symbols to get information about the stock.',
            link: 'https://mystockticker-ps.netlify.app',
            icon: MyStockImg
        },
        {
            title: 'My Weather App',
            description:'It is a simple Weather App that I built in plain JavaScript. Enter the city name, and it will display weather information.',
            link: 'https://myweatherapp-ps.netlify.app',
            icon: MyWeatherAppImg
        },


    ],
    availability:[
        {
            available: 'Open to Work',
            notAvailable: 'Not Available'
        }
    ]
}