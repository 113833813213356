import React from 'react';
import {  Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Badge from 'react-bootstrap/Badge';
import { faCode } from '@fortawesome/free-solid-svg-icons';

import ResumeData from '../../utils/resumeData';

import '../Footer/Footer.css'; 
import { motion } from "framer-motion";


const Footer = () => {
    return (

        <div className='footer'>
            <div className='footer-left'>
                <Typography className='footer-name'>
                    <Badge pill variant="info">Pavlo Shchegol</Badge> 
                </Typography>
            </div>

            <div className='footer-right'>
                <Typography className='copy-rights'>
                <Badge pill variant="info">{ResumeData.title} <FontAwesomeIcon icon={faCode}></FontAwesomeIcon></Badge>
                </Typography>
            </div>
        </div>

        );
}
 
export default Footer; 