import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { faPhone,faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ResumeData from '../../utils/resumeData';
import Resume from '../../pages/resume/Resume';



const DropDownButton = ({text})  => {
    return (  

        <DropdownButton 
        menuAlign="right"
        title={text}
        id="dropdown-menu-align-right"
        variant="info"
        >
        <Dropdown.Item eventKey="1" href={"tel:" + ResumeData.phone}><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> {ResumeData.phone} </Dropdown.Item>
        <Dropdown.Item eventKey="2" href={"mailto:" + ResumeData.email}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> {ResumeData.email}</Dropdown.Item>
        </DropdownButton>
    );
}
 
export default DropDownButton;